
import { computed, defineComponent, ref } from 'vue'
import type { FlagCountry, StatusType, TableHeaders } from '@/definitions/shared/types'
import { useModes } from '@/compositions/modes'
import { getTableData } from '@/services/tableService'
import { filterItemsByValues } from '@/services/utils'
import { formatDate } from '@/services/dateTimeService'
import LookupHistoryTable from '@/components/pages/services/carrierLookup/history/LookupHistoryTable.vue'
import LookupHistoryFilter from '@/components/pages/services/carrierLookup/history/LookupHistoryFilter.vue'
import LookupHistoryEmpty from '@/components/pages/services/carrierLookup/history/LookupHistoryEmpty.vue'

type History = {
  phone: string
  status: StatusType
  type: StatusType
  country: FlagCountry
  carrier: string
  dateCreated: string
}

export default defineComponent({
  name: 'ServicesCarrierLookupHistory',
  components: { LookupHistoryEmpty, LookupHistoryFilter, LookupHistoryTable },
  setup() {
    const { isEmptyMode } = useModes()
    const search = ref('')
    const selected = ref<History[]>([])
    const tableItems: History[] = getTableData('carrierLookupHistory')
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Phone', value: 'phone-slot' },
      { text: 'Status', value: 'status-slot' },
      { text: 'Type', value: 'type-slot' },
      { text: 'Country', value: 'country-slot' },
      { text: 'Carrier', value: 'carrier' },
      { text: 'Date created', value: 'dateCreated', format: (val: string) => formatDate(val) },
    ])

    const filteredTableItems = computed<History[]>(() =>
      tableItems.filter(({ phone, status, type, country, carrier }) =>
        filterItemsByValues(search.value, [
          phone,
          status.name,
          type.name,
          country.name,
          carrier,
        ])
      )
    )

    return {
      isEmptyMode,
      search,
      selected,
      tableItems,
      tableHeaders,
      filteredTableItems,
    }
  },
})
