
import { defineComponent } from 'vue'
import TmTableActionButton from '@/components/shared/table/TmTableActionButton.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import useCarrierLookupOpenModal from '@/compositions/services/useCarrierLookupOpenModal'

export default defineComponent({
  name: 'LookupHistoryDropdown',
  components: { TmDropdownItem, TmDropdown, TmTableActionButton },
  props: {
    selected: {
      type: Array,
    },
  },
  setup(props) {
    const { openViewDetailsModal, openDeleteModal } = useCarrierLookupOpenModal()

    return {
      openViewDetailsModal,
      openDeleteModal,
    }
  },
})
